/* creo una funzione che ritorna le api da usare in dev e in prod */
export const getApi = () => {
    let api;
    if (process.env.NODE_ENV === 'production') {
        api = 'https://back.gestorebagnipro.it/';
    } else {
        api = 'http://192.168.1.15:5000/';
    }
    
    return api;
    }
