import React,{useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import './css/mostratutti.css'; // Importa il tuo file CSS per lo stile
import { updateStatoPulizia,uploadImagePulizia,aggiungiNotaPulizia } from '../../../api/prodotti/api';
import { set } from 'lodash';

const MostraGiro = ({ points,recalculate,apriModalAssociaConsegna,updateData }) => {

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [newPoints, setPoints] = useState(points); // Rinomina la variabile per evitare confusione con il parametro [points
  // Controllo se esistono punti da mostrare

  const [raggruppaPerOrario, setRaggruppaPerOrario] = useState('');
  const [debouncedRaggruppaPerOrario, setDebouncedRaggruppaPerOrario] = useState(raggruppaPerOrario);

  const [raggruppaPerKm, setRaggruppaPerKm] = useState('');
  const [debouncedRaggruppaPerKm, setDebouncedRaggruppaPerKm] = useState(raggruppaPerKm);

  const [searchParams] = useSearchParams();

  const paramValue = searchParams.get('param');

  const isAppended = useRef(false);

  const [statoPulizia, setStatoPulizia] = useState({});
  const [uploadStatus, setUploadStatus] = useState("");
  const [notaIsOpen, setNotaIsOpen] = useState(false);
  const [nota, setNota] = useState({});
  const fileInputRef = useRef(null);

  const[indexAction,setIndexAction] = useState();


  const getCity = async (lat, lon) => {
    console.log(process.env.REACT_APP_OPENWEATHERMAP_API_KEY)
    try {
      const response = await axios.get(`https://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&limit=1&appid=${process.env.REACT_APP_OPENWEATHERMAP_API_KEY}`);
      console.log(response);
      return response.data[0]?.name || 'Unknown location'; // Ritorno il nome della città
    } catch (error) {
      setError(true);
      setErrorText(error.message);
      return 'Unknown location'; // In caso di errore, ritorno un valore di fallback
    }
  };
  const raggruppaPerOrdineId = (points) => {
    return points.reduce((acc, point) => {
      if (!point.lat) {
        acc.push({ isDelimiter: true }); // Aggiungo una riga vuota
      } else {
        const lastGroup = acc[acc.length - 1];
        if (lastGroup && Array.isArray(lastGroup) && lastGroup[0].ordineId?._id === point.ordineId?._id) {
          lastGroup.push(point); // Aggiungo al gruppo esistente
        } else {
          acc.push([point]); // Crea un nuovo gruppo
        }
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (points?.length) {
      const getCityNames = async () => {
        const newPointsTmp = [];
        let ponintPercorso = [];
        for (const point of points) {
            const city = await getCity(point.lat, point.lng);
            let giorno = new Date(point.ora).toLocaleDateString();
            let ora = new Date(point.ora).toLocaleTimeString();
            if(point.lat){
              ponintPercorso.push([point.lat,point.lng]);
          }else{
              if(ponintPercorso.length > 0){
                  ponintPercorso = [];
              }
          }
            newPointsTmp.push(
              { ...point, 
                city,ora:`${giorno} - ${ora}`,
                percorso:ponintPercorso
              });
          
        }
        const raggruppati = raggruppaPerOrdineId(newPointsTmp);
        setPoints(raggruppati);
      };
      getCityNames();
    }
  }, [points]);

  const generaPercorso = (ponintPercorso) => {
    // genero il percorso con questo schema https://www.google.com/maps/dir/?api=1&destination=41.9028,12.4964&waypoints=43.7696,11.2558|42.5052,12.6518
    ponintPercorso = ponintPercorso.filter((point, index, self) => self.findIndex((t) => t[0] === point[0] && t[1] === point[1]) === index);
      let url = 'https://www.google.com/maps/dir/?api=1&destination=';
      let percorso = '';
      let counter = 0;
      for (const point of ponintPercorso) {
          if(counter == 0){
              url += point[0]+','+point[1];
              counter++;
              continue;
          }else if(counter == 1){
              url += '&waypoints=';
              counter++;
          }
  
          percorso += point[0]+','+point[1]+'|';
      }
      percorso = percorso.substring(0, percorso.length - 1);
      url += percorso;
      window.open(url, '_blank');
  
    } 

    useEffect(() => {
      if(debouncedRaggruppaPerOrario || debouncedRaggruppaPerKm){
        let newRaggruppaperOrario ;
        let newRaggruppaPerKm ;
        if (!debouncedRaggruppaPerOrario && !debouncedRaggruppaPerKm) return;
        if (debouncedRaggruppaPerOrario){
         newRaggruppaperOrario = debouncedRaggruppaPerOrario * 60 * 60 * 1000;
        }
        if (debouncedRaggruppaPerKm){
         newRaggruppaPerKm = debouncedRaggruppaPerKm * 1000;
        }
        recalculate(newRaggruppaperOrario,newRaggruppaPerKm);
      }
    }, [debouncedRaggruppaPerOrario,debouncedRaggruppaPerKm]);


    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedRaggruppaPerOrario(raggruppaPerOrario);
      }, 500); // 500ms debounce
  
      return () => {
        clearTimeout(handler);
      };
    }, [raggruppaPerOrario]);
  
    // Debounce per "Raggruppa per km"
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedRaggruppaPerKm(raggruppaPerKm);
      }, 500); // 500ms debounce
  
      return () => {
        clearTimeout(handler);
      };
    }, [raggruppaPerKm]);


    const condividiPercorso = (data,token) => {
      console.log(data,token);
    }

    const apriModal = (data) => {
      apriModalAssociaConsegna(data);
    }
    const changeStatoConsegna = async (stato,ordineId,index) => {
      //updateConsegnaOrdine(stato,ordineId);
      console.log(stato,ordineId,index);
      console.log(newPoints[index].filter((p) => p.ordineId === ordineId));
      let point = newPoints[index].filter((p) => p.ordineId === ordineId);
      //setStatoPulizia({...statoPulizia, [point[0].ordineId.pulizia_id]:stato});
      const id_pulzie = point.map((p) => p.pulizia_id);
      const resp = await updateStatoPulizia(id_pulzie,stato);
      console.log(resp);
      /*let newPointsTmp = [...newPoints];
      newPointsTmp.map((point, index) => {
        console.log(point);
        if (point.length){
          point.map((p) => {
            if(p.ordineId === ordineId){
              p.statoConsegna = stato;
            }
          })
        }
      })
      console.log(newPointsTmp);
      setPoints([...newPointsTmp]);*/
      updateData();
    }



    const handleFileChange = async (e) => {
      let index = indexAction.index;
      let ordineId = indexAction.ordineId;
      let id_pulzie = newPoints[index]//.map((p) => p.pulizia_id);


      id_pulzie = id_pulzie.filter((p) => p.ordineId === ordineId).map((p) => p.pulizia_id);
      const file = e.target.files[0];
  
      if (!file) {
        setUploadStatus("No file selected.");
        return;
      }
  
      const formData = new FormData();
      formData.append("image", file);
      let dateOggi = new Date();
      // trasformo data in stringa
      dateOggi = dateOggi.toISOString();
      formData.append("name",dateOggi);
  
      try {
        setUploadStatus("Uploading...");
        const response = await uploadImagePulizia(formData, id_pulzie);
        setUploadStatus(`Upload successful! Image` );
      } catch (error) {
        setUploadStatus("Upload failed. Try again.");
        console.error(error);
      }
      setIndexAction();
    };
  
    const handleButtonClick = (index,ordineId) => {
      //const id_pulzie = newPoints[index].map((p) => p.pulizia_id);
      //console.log(id_pulzie);
      setIndexAction({index,ordineId});
      fileInputRef.current.click(); // Attiva il caricamento file
    };

    const aggiungiNota = async (index,ordineId) => {
      let point = newPoints[index].filter((p) => p.ordineId === ordineId);

      let id_pulzie = point.map((p) => p.pulizia_id);
      const notaTmp = nota[index[ordineId]];
      console.log(notaTmp);
      const resp = await aggiungiNotaPulizia(id_pulzie,notaTmp);
      console.log(resp);
      setNotaIsOpen(!notaIsOpen);
      setIndexAction();
      updateData();
    }

    
  return (
    <>
      <div className="raggruppa-dati">
      {!paramValue ? 
      <>
      <div className="raggruppa-dati-element">
          <label>Raggruppa per orario:</label>
          <input type= "number" min={1} placeholder="...orario" onChange={(e) => setRaggruppaPerOrario(e.target.value)}></input>
      </div>
      <div className="raggruppa-dati-element">
          <label>Raggruppa per km:</label>
          <input type= "number" min={1} placeholder="...km" onChange={(e) => setRaggruppaPerKm(e.target.value)}></input>
      </div></>: null}
        <div >
            <button className="btn-table" onClick={() => recalculate(null,null,true)}>raggruppa per area</button>
          </div>
      </div> 

    <div className="table-container">
      <table className="responsive-table">
        <thead>
          <tr>
            <th>Ordine</th>
            <th>Etichetta</th>
            <th>Cliente</th>
            <th>Città</th>
            <th>Orario</th>
            <th>Pulito</th>
            <th>Area</th>
            <th>Assegnato</th>
            <th>Nota</th>
            <th>upload</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {/*newPoints.map((point, index) => (
            <>
            {
              !point.lat ?  
              <tr key={index}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>:
            <tr key={index}>
            <td>{point.info}</td>
            <td>{point.cliente}</td>
            <td>{point.city}</td>
            <td>{point.ora}</td>
            <td>
              <button className='btn-table' onClick={(e)=>{generaPercorso(point.percorso)}}>Genera percorso</button>
              <button className="btn-table" onClick={()=>apriModal(point)}>Condividi</button>
              <button className='btn-table'>Elimina</button>
            </td>
          </tr>
            }
            </>
          ))*/}          
          {newPoints.map((group, index) => (
            <>
            {/* Verifica se il gruppo è un delimitatore */}
            {group.isDelimiter ? (
              <tr key={index}>
                <td colSpan="9" className='delimiter'></td> {/* Riga vuota per il delimitatore */}
              </tr>
            ) : (
              <tr key={index} className={index % 4 == 0 ? 'blue':'red'}>
                {/* Assicurati che group sia un array */}
                <td  className='internal-row'>{Array.isArray(group) ? [...new Set(group.map((p) => p.ordineId))].map((e,i) =><tr>{e}</tr> ) : ''}</td>
                <td data-label="etichetta" className='internal-row'>
                  {Array.isArray(group) ? (
                    [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                      const info = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.info)
                        .join(', ');

                      return (
                        <tr key={ordineId} className='internal-row'>
                          <td  className='internal-row'>{info}</td>
                        </tr>
                      );
                    })
                  ) : (
                   <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  )}
                </td>
                <td  data-label="cliente" className='internal-row'>
                  {Array.isArray(group) ? (
                    [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                      let info = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.cliente)
                        .join(', ');
                      // rendo univoco il nome del cliente
                      info = [...new Set(info.split(', '))].join(', ');

                      return (
                        <tr key={ordineId} className='internal-row'>
                          <td  className='internal-row'>{info}</td>
                        </tr>
                      );
                    })
                  ) : (
                   <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  )}
                </td>
                <td data-label="città" className='internal-row'>
                  {Array.isArray(group) ? (
                    [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                      let info = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.city)
                        .join(', ');
                      // rendo univoco il nome del cliente
                      info = [...new Set(info.split(', '))].join(', ');
                      
                      return (
                        <tr key={ordineId} className='internal-row'>
                          <td  className='internal-row'>{info}</td>
                        </tr>
                      );
                    })
                  ) : (
                   <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  )}
                </td>
                <td data-label="giorno" className='internal-row'>
                  {Array.isArray(group) ? (
                    [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                      let info = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.ora)
                        .join(', ');
                      // rendo univoco il nome del cliente
                      info = [...new Set(info.split(', '))].join(', ');
                      
                      return (
                        <tr key={ordineId} className='internal-row'>
                          <td  className='internal-row'>{info}</td>
                        </tr>
                      );
                    })
                  ) : (
                   <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  )}
                </td>
                <td data-label="pulito" className='internal-row'>
                  {Array.isArray(group) ? (
                    [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                      let info = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.stato)
                        .join(', ');
                      // rendo univoco il nome del cliente
                      info = [...new Set(info.split(', '))].join(', ');
                      let etichetta = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.info)
                        .join(', ');
                      
                      
                      return (
                      <tr>
                        <td  className='internal-row'>
                          <span className='etichetta-responsive'>
                          {etichetta}
                          </span>
                          <select className='custom-select-giri-consegne' value={statoPulizia[ordineId]?.pulizia_id || info} onChange={(e)=>changeStatoConsegna(e.target.value,ordineId,index)  } >
                            <option value="no">No</option>
                            <option value="si">Si</option>
                          </select>
                        </td>
                      {/*<tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                      </tr>*/}
                      </tr>
                      );
                    })
                  ) : (
                   <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  )}
                </td>
                <td data-label="area"  className='internal-row'>
                  {Array.isArray(group) ? (
                    [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                      let info = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.area)
                        .join(', ');
                      // rendo univoco il nome del cliente
                      info = [...new Set(info.split(', '))].join(', ');
                      
                      return (

                      <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                      </tr>
                      
                      );
                    })
                  ) : (
                   <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  )}
                </td>
                <td data-label="collaboratore" className='internal-row'>
                  {Array.isArray(group) ? (
                    [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                      let info = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.collaboratoreId)
                        .join(', ');
                      // rendo univoco il nome del cliente
                      info = [...new Set(info.split(', '))].join(', ');
                      
                      return (

                      <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>{info}</td>
                            
                      </tr>
                      
                      );
                    })
                  ) : (
                   <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  )}
                </td>
                <td data-label="note"  className='internal-row'>
                  {Array.isArray(group) ? (
                    [...new Set(group.map((p) => p.ordineId))].map((ordineId) => {
                      let info = group
                        .filter((p) => p.ordineId === ordineId)
                        .map((p) => p.nota)
                        .join(', ');
                      // rendo univoco il nome del cliente
                      info = [...new Set(info.split(', '))].join(', ');
                      let etichetta = group
                      .filter((p) => p.ordineId === ordineId)
                      .map((p) => p.info)
                      .join(', ');
                      return (

                      <tr key={ordineId} className='internal-row'>
                            <td  className='internal-row'>
                            <span className='etichetta-responsive'>
                          {etichetta}
                          </span>
                              {info}
                              </td>
                            {!notaIsOpen || indexAction !== index+ordineId ?
                             <button className="btn-table" onClick={()=>{{
                              setNotaIsOpen(!notaIsOpen)
                              setIndexAction(index+ordineId)
                              }}}>nota</button>: 
                              <>
                              nota
                              <textarea  onChange={(e)=>setNota({...nota,[index[ordineId]]:e.target.value})}></textarea>
                              <button className="btn-table" onClick={()=>aggiungiNota(index,ordineId)}>aggiungi</button>
                              <button className="btn-table" onClick={()=>setNotaIsOpen(!notaIsOpen)}>Chiudi</button>
                              </>
                              }
                      </tr>
                      
                      );
                    })
                  ) : (
                   <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                  )}
                </td>
                <td  className='internal-row'>{Array.isArray(group) ? [...new Set(group.map((p) => p.ordineId))].map((e,i) =>{
                  let etichetta = group
                  .filter((p) => p.ordineId === e)
                  .map((p) => p.info)
                  .join(', ');
                  return(
                  <tr>
                  {paramValue && 
                     <div style={{ padding: "20px" }}>
                        <span className='etichetta-responsive'>
                          {etichetta}
                          </span>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={(e)=>handleFileChange(e,index)}
                          style={{ display: "none" }} // Nascondi l'input
                        />
                        <button className="btn-table" onClick={()=>handleButtonClick(index,e)}>
                          Upload Image
                        </button>
                        <p>{uploadStatus}</p>
                      </div>
                      }
                </tr> )}) : ''}</td>
                <td  className='internal-row'>
                  {Array.isArray(group) && (
                    <>
                      <button
                        className="btn-table"
                        onClick={() => generaPercorso(group.flatMap((p) => p.percorso))}
                      >
                      Percorso
                      </button>    

                    </>
                  )}
                </td>
              </tr>
            )}
          </>
        ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default MostraGiro;
