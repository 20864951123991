// src/serviceWorkerRegistration.js

// Registra il Service Worker
export function register() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/sw.js") // Percorso al Service Worker (cartella public)
      .then((registration) => {
        console.log("Service Worker registrato con successo:", registration);
      })
      .catch((error) => {
        console.error("Errore nella registrazione del Service Worker:", error);
      });
  } else {
    console.warn("Il browser non supporta i Service Worker.");
  }
}

// Deregistra il Service Worker
export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error("Errore durante la deregistrazione del Service Worker:", error);
      });
  }
}
