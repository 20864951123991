export async function subscribeUserToPush() {
  if (!("serviceWorker" in navigator)) {
    console.error("Service Workers not supported.");
    return null;
  }

  const registration = await navigator.serviceWorker.ready;

  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array('BLKkLJ6kffOPRqs4IPInKllLwLCN3cJnzNrE1sHRb0RKx30L6k4bQOiD1zVpXuYnR3ovuAYn8sTlMFsqA9YGSgE'),
  });

  const token = localStorage.getItem("token");
  console.log("User is subscribed:", subscription);

  // Salva la subscription nel server
  await fetch("https://back.gestorebagnipro.it/pushNotification/subscription", {
    method: "POST",
    body: JSON.stringify(subscription),
    headers: {
      "Content-Type": "application/json",
       'authorization': `Bearer ${token}`
    },
  });

  return subscription;
}

function urlBase64ToUint8Array(base64String) {
  if (!base64String) {
    throw new Error("La chiave VAPID_PUBLIC_KEY non è definita o è vuota.");
  }

  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

