import React, { useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom'; // Notare che Router non è più necessario qui
import Home from './components/home/Home';
import Gestore from './components/gestore/Gestore';
import Dashboard from './components/gestore/Dashboard';
import Profilo from './components/gestore/Profilo';
import Noleggio from './components/gestore/Noleggio';
import GiroPulizieGuest from './components/guest/GiroPulizieGuest'; 
import GiroConsegneGuest from './components/guest/GiroConsegneGuest'; 
import GestioneCollaboratori from './components/gestore/GestioneCollaboratori';
import Collaboratori from './components/gestore/Collaboratori';
import Client from './components/client/Client';
import OnboardingRefresh from './components/stripe/OnboardingRefresh';
import OnboardingSuccess from './components/stripe/OnboardingSuccess';
import CheckoutCancel from './components/stripe/CheckoutCancel';
import CheckoutSuccess from './components/stripe/CheckoutSuccess';
import Magazzino from './components/gestore/Magazzino';
import logo from './image/bitmap.png';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { login } from './redux/slice/authSlice';

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.auth.isLogin);
  const navigate = useNavigate(); // Usa useNavigate qui

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(login());
    }
  }, [isLogin]);

  useEffect(() => {
    const currentUrl = window.location.href;

    if (currentUrl.includes('pulizie=')) {
      console.log('pulizie')
      navigate('/giro_pulizie_guest');
    } else if (currentUrl.includes('consegne=')) {
      console.log('consegne')
      navigate('/giro_consegne_guest');
    } else if (currentUrl.includes('client=')) {
      navigate('/client');
    }
  }, [navigate]);

  return (
    <>
      <nav className='nav-bar'>
        <div className='logo-nav-bar'>
          <img src={logo} alt='React logo' />
        </div>
        
        <h1 className='header-nav-bar'></h1>
        <ul className='ul-nav-bar'>
          {!isLogin ? (
            <>
              <li className='li-nav-bar'><Link to="/">Home</Link></li>
              <li className='li-nav-bar'><Link to="/gestore">Login</Link></li>
            </>
          ) : (
            <>
              <li className='li-nav-bar'><Link to="/gestore/magazzino" >Magazzino</Link></li>
              <li className='li-nav-bar'><Link to="/gestore/profilo">Profilo</Link></li>
              <li className='li-nav-bar'><Link to="/gestore/noleggio">Noleggio</Link></li>
              <li className='li-nav-bar'><Link to="/gestore/collaboratori">Workers</Link></li>
            </>
          )}
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gestore" element={<Gestore />} />
        <Route path="/gestore/dashboard" element={<Dashboard />} />
        <Route path="/gestore/profilo" element={<Profilo />} />
        <Route path="/gestore/noleggio" element={<Noleggio />} />
        <Route path="/giro_pulizie_guest" element={<GiroPulizieGuest />} />
        <Route path="/giro_consegne_guest" element={<GiroConsegneGuest />} />
        <Route path="/gestore/collaboratori" element={<Collaboratori />} />
        <Route path="/client" element={<Client />} />
        <Route path="/onboarding/refresh" element={<OnboardingRefresh />} />
        <Route path="/onboarding/success" element={<OnboardingSuccess />} />
        <Route path="/checkout/cancel" element={<CheckoutCancel />} />
        <Route path="/checkout/success" element={<CheckoutSuccess />} />
        <Route path="/gestore/magazzino" element={<Magazzino />} />
        {/*<Route path="/gestore/collaboratori/aggiungi" element={<GestioneCollaboratori />} />*/}
      </Routes>
    </>
  );
}

export default App;
