import { getApi } from '../api';


export const sendNotification = async (title,message) => {
    const token = localStorage.getItem('token');
    try {
        const response = await fetch(`${getApi()}pushNotification/sendNotification/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                 'authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ title,message })
        });

        if (!response.ok) {
            throw new Error(`Errore nella richiesta: ${response.status}`);
        }

        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Errore nella funzione getPuliziaFromOrdine:", error);
        return null;
    }
};


