import { useState, useEffect } from 'react';
import { giveMeAllBagniUtente } from '../../api/prodotti/api';
import { sendNotification } from '../../api/notification/api';
import MostraTutti from '../map/MostraTutti';
import MostraTabella from './tabelle/MostraTutti'; // Correzione del nome dell'import
import table from '../../image/table.png'; // Importa l'immagine per il bottone
import map from '../../image/map.png';
import './css/Totali.css';

const Totali = ({ option }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [mostraMappa, setMostraMappa] = useState(false);
  const [mostraTabella, setMostraTabella] = useState(false);

  const [callSubscription, setCallSubscription] = useState(false);


  const getAllBagniUtente = async () => {
    if (option == 'bagni'){
        try {
        const response = await giveMeAllBagniUtente();
        console.log(response);
        let dataTmap = [];
        response.forEach((element) => {
            dataTmap.push({
            lat: element.posizione.coordinates[1],
            lng: element.posizione.coordinates[0],
            info: element.numero,
            city: element.city,
            });
        });

        setData(dataTmap);
        setLoading(false);
        setMostraTabella(true);
        } catch (error) {
        setError(true);
        setErrorText(error.message);
        setLoading(false);
        }
    }
  };

  const givMeNotification = async () => { 
    if (callSubscription) {
      return;
    }
    try {
      const response = await sendNotification('title','message');
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setCallSubscription(true);
  }
  useEffect(() => {
    getAllBagniUtente();
  }, [option]);

  useEffect(() => {
    givMeNotification();
  }, []);

  return (
    <div className='totali'>
      <h2>Totali</h2>
      {loading && <p>Caricamento...</p>}
      {error && <p>Si è verificato un errore: {errorText}</p>}
      {!loading && !error && (
        <div>
          {data.length === 0 && <p>Nessun bagno trovato</p>}
          {data.length > 0 && (
            <div>
              <p>Trovati {data.length} bagni</p>
              <div className='cont-button-bagni-totali'>
                {/* Corretto l'uso delle funzioni di stato per mostrare mappa e tabella */}
                {/* Corretto l'uso delle funzioni di stato per mostrare mappa e tabella */}
                <img src={map} title="mostra mappa" className='pointer-img' alt='mappa'onClick={() => {
                    setMostraMappa(true);
                    if (mostraTabella) {
                      setMostraTabella(false);
                    }
                  }} />
                <img src={table} title="mostra tabella" className='pointer-img' alt='tabella' onClick={() => {
                    setMostraTabella(true);
                    if (mostraMappa) {
                      setMostraMappa(false);
                    }
                  }} />
                
                {/*<button
                  onClick={() => {
                    setMostraMappa(!mostraMappa);
                    if (mostraTabella) {
                      setMostraTabella(false);
                    }
                  }}
                >
                  Mostra sulla mappa
                </button>
                <button
                  onClick={() => {
                    setMostraTabella(!mostraTabella);
                    if (mostraMappa) {
                      setMostraMappa(false);
                    }
                  }}
                >
                  Mostra tabella
                </button>*/}
              </div>
              {mostraMappa && <MostraTutti points={data} />}
              {mostraTabella && <MostraTabella points={data} />}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Totali;
